import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Box, Card, IconButton, Stack} from "@mui/material";
import {Creative} from "../../models/CreativesList";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {useTranslation} from "react-i18next";
import '../../../../../styles/views/creatives/creative-details.scss';
import Typography from "@mui/material/Typography";
import NoData from "../../../../common/NoData";
import {isNullOrEmpty} from "../../../../../utils/helpers";
import 'material-symbols/outlined.scss'

interface Props {
    creative: Creative
}

const CREATIVE_HEIGHT = 600;

const CreativePreview = ({creative}: Props) => {
    const [t, i18n] = useTranslation('locale');
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState('100%');

    const fullScreenElement = useRef(null);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleTransitionEnd = () => {
        if (open && fullScreenElement.current) {
            const fullScreenElementHeight = fullScreenElement.current.offsetHeight; // Get the height
            const fullScreenElementWidth = fullScreenElementHeight * (creative.dimensions.width / creative.dimensions.height); // Calculate the new width
            setWidth(`${fullScreenElementWidth}px`); // Apply the new width
        }
    };

    function setCreativeWidth() {
        var w = creative.dimensions ? (height * (creative.dimensions.width / creative.dimensions.height)).toString() : '100%';
        setWidth(w);
    }

    const handleClose = () => {
        setOpen(false);
        setCreativeWidth();
    };

    useEffect(() => {
        setCreativeWidth()
    }, []);

    const getMaxGridWidth = () => {
        return document.getElementsByClassName('page-title')[0].clientWidth;
    };

    const renderCreative = (height: string) => {
        switch (true) {
            case creative.mime_type.includes('video'):
                return (
                    <video style={{objectFit: 'scale-down'}} controls autoPlay height={height}>
                        <source src={creative.asset_url} type={creative.mime_type}/>
                    </video>);
            case creative.mime_type.includes('html'):
                return (<iframe src={creative.asset_url} style={{border: 'none', overflow: "hidden"}} width={width}
                                height={height}></iframe>);
            case creative.mime_type.includes('image'):
                return (
                    <img style={{objectFit: 'scale-down'}} src={creative.asset_url} alt='creative' height={height}/>);
            default:
                return null;
        }
    }

    const height = creative.dimensions && creative.dimensions.height < CREATIVE_HEIGHT ? creative.dimensions.height : CREATIVE_HEIGHT;

    return (
        <Box sx={{width: getMaxGridWidth()}}>
            <Card sx={{p: '16px', bgcolor: 'background.default'}}>
                {isNullOrEmpty(creative.asset_url) ?
                    (
                        <Box sx={{height:CREATIVE_HEIGHT}} component={Stack} direction="column" justifyContent="center">
                            <NoData showIcon={"cloud_download"} title={t("creativeDetails.noAssetUrl.title")}
                                    description={t("creativeDetails.noAssetUrl.description")} iconStyle={"material-symbols-outlined  size-24-outlined"}/>
                        </Box>
                    ) :
                    (
                        <Box className="creative-preview-box"
                             onClick={handleOpen}>
                            {renderCreative(height.toString())}
                            <Typography component="div">
                                <Box className="creative-preview-overlay">
                                    <FullscreenIcon/>{t('creatives.fullscreen')}
                                </Box>
                            </Typography>
                        </Box>
                    )
                }
            </Card>

            {/* Fullscreen Dialog */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionProps={{ onEntered: handleTransitionEnd }}
                sx={{width: '100%', height: '100%'}}
            >
                <IconButton
                    edge="end"
                    onClick={handleClose}
                    sx={{position: 'absolute', right: 30, top: 15}}>
                    <CloseIcon sx={{fontSize: '3rem'}}/>
                </IconButton>

                <Box ref={fullScreenElement} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    height: 'calc(100% - 2rem)'
                }}>
                    {renderCreative('100%')}
                </Box>
            </Dialog>
        </Box>
    )
};

export default CreativePreview;