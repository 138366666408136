export enum Status {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED'
}
export enum MimeType {
	IMAGE = 'image',
	VIDEO = 'video',
	HTML = 'html'
}

interface DspProvider {
	name: string;
}

interface Advertiser {
	dsp_provider: DspProvider;
	name: string;
}
export interface IABCategoryWithoutParent {
	code: string;
	name: string;
	id: string;
	source: string;
}
export interface IABCategory {
	code: string;
	name: string;
	id: string;
	category_id: string;
	source: string;
	origin: number;
	parent: IABCategoryWithoutParent;
}
export interface IABCategoryTreeNode {
	code: string;
	name: string;
	id: string;
	label: string;
	value:string;
	groupName: string,
	subCategory: string,
	included:boolean,
	tooltip: string,
	parent?:IABCategoryTreeNode | null,
	disabled:boolean,
}

export interface Dimensions {
	width: number;
	height: number;
}

export interface Creative {
	create_date: string;
	update_date: string;
	advertiser: Advertiser;
	file_size: number;
	id: string;
	dealid:string;
	asset_url: string|undefined;
	categories: IABCategory[];
	mime_type: string;
	status: Status;
	adomain: string[];
	adid: string;
	dimensions: Dimensions;
	name: string;
	rejection_reason: string;
}

export interface CreativeStatusUpdateParams {
	creativeId: string;
	status: Status.REJECTED | Status.APPROVED;
	rejectReason?: string;
}
